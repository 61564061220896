<template>
  <div class="job">
    <el-row class="btns">
      <el-image
        @click="onToBack"
        :src="require(`@/assets/image/blueBack.png`)"
        class="backImg"
      ></el-image>
      <el-select v-model="selectWorkId" placeholder="请选择" size="48px">
        <el-option
          v-for="item in workList"
          :label="item.entrepot_name"
          :value="item.shop_user_class_entrepot_id"
          :key="item.shop_user_class_entrepot_id"
        >
        </el-option>
      </el-select>
    </el-row>
    <el-row class="classList">
      <el-col
        class="classItem"
        v-for="item in Correcting"
        :key="item.shop_user_class_entrepot_id"
        type="flex"
        justify="space-between"
      >
        <el-row class="itemTop" type="flex" justify="space-between">
          <el-col :span="24" class="jobName">{{ item.name }}</el-col>
        </el-row>
        <div class="itemMainBox">
          <el-row class="itemMain" @click.native="goDetail(item)">
            <el-col :span="24">
              <div class="classTitle">
                <el-image
                  class="mainImg"
                  :src="require(`@/assets/image/jobIcon.png`)"
                ></el-image>
                <div class="className">查看作业</div>
              </div>
              <div class="checkJob">
                作业打分：<span>{{ item.allot_count || "未打分" }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getClassEntrepot, getClassEntrepotJob } from "@/request/api";
// import UploadModal from '@/components/UploadModal.vue'

export default {
  // components: {
  //   UploadModal
  // },
  data() {
    return {
      workList: [], // 作业列表
      Correcting: [], // 批阅列表
      selectWorkId: "", //选择作业
    };
  },
  watch: {
    selectWorkId(value) {
      this.onGetClassEntrepotJob(value);
    },
  },
  methods: {
    async init() {
      await this.onGetClassEntrepot(this.$route.query.shop_user_class_id);
      this.selectWorkId = Number(this.$route.query.shop_user_class_entrepot_id);
    },
    async onGetClassEntrepot(shop_user_class_id) {
      const ret = await getClassEntrepot(shop_user_class_id);
      if (ret.status === 200) {
        this.workList = ret.data.data;
      }
    },
    async onGetClassEntrepotJob(shop_user_class_entrepot_id) {
      const ret = await getClassEntrepotJob(shop_user_class_entrepot_id);
      if (ret.status === 200) {
        this.Correcting = ret.data.data;
      }
    },
    goDetail(item) {
      this.$router.push({
        path: "/aiClass/jobCorrectingDetail",
        query: {
          shop_user_class_entrepot_job_id: item.shop_user_class_entrepot_job_id,
          name: item.name,
          // shop_user_class_entrepot_id: this.selectWorkId,
        },
      });
    },
    onToBack() {
      this.$router.back();
    },
    onAllocationClassSubmit() {},
    conversion(time) {
      const date = new Date(time);
      const Y = date.getFullYear();
      const M = date.getMonth() + 1;
      const D = date.getDate();
      return Y + "-" + M + "-" + D;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.job {
  .btns {
    margin: 41px 0px 41px 51px;

    display: flex;
    align-items: center;
    .backImg {
      width: 40px;
      margin-right: 20px;
      cursor: pointer;
    }
    .btn {
      width: 149px;
      line-height: 17px;
      font-size: 20px;
      border-radius: 10px;
    }
    /deep/ .el-input__inner {
      height: 41px;
      line-height: 41px;
      font-size: 20px;
      width: 216px;
    }
    .creactBtn {
      // background: #91d883;
      // border: 1px solid #91d883;
      margin: 0px 48px;
    }
  }
  .classList {
    // margin-left: 60px;
    .classItem {
      width: 298px;
      height: 273px;
      margin-left: 51px;
      margin-bottom: 60px;
      .itemTop {
        // height: 58px;
        border-radius: 10px 10px 0px 0px;
        background: #91d883;
        color: #fff;
        font-size: 24px;
        line-height: 48px;
      }
      .jobName {
        margin-left: 20px;
      }
      .topIcon {
        margin-right: 20px;
        line-height: 48px;
        text-align: right;
        cursor: pointer;
      }
      .itemMainBox {
        border: 1px solid #91d883;
        border-radius: 0px 0px 10px 10px;
        background: #fff;
        border-top: 0px;
        margin: 0 auto;
        padding: 26px 20px;
        cursor: pointer;
      }
      .itemMain {
        width: 258px;
        height: 124px;
        padding: 20px;
        background: #f2f7ff;
        border-radius: 10px 10px 10px 10px;
        .classTitle {
          display: flex;
          // text-align: left;
          // justify-content: left;
        }
        .className {
          color: #0066ff;
          line-height: 39px;
          margin-left: 15px;
        }
        .checkJob {
          font-size: 16px;
          margin-top: 20px;
          span {
            color: #61a0ff;
          }
        }
        .readOverBtn {
          width: 128px;
          line-height: 11px;
          margin-top: 20px;
          margin-left: 25px;
        }
      }
      .mainImg {
        width: 39px;
        height: 39px;
      }
      .mainNumBox {
      }
      .mainNumTitle {
        font-size: 20px;
      }
      .mainNum {
        font-size: 22px;
        color: #0066ff;
      }
    }
  }
}
</style>
